footer {
    width: 100%;
    height: 50px;
    border-top: 1px solid rgba(41, 41, 41, 0.5);
    position: fixed;
    bottom: 0;
    background: var(--color-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-shadow: var(--light-shadow);
}
.footer__socials a{
    color: var(--color-btn);
    margin: 0 13px;
}
.footer__socials a:hover{
    color: #f71735;
}


 /* media query (medium devices)*/
 @media screen and (max-width: 1024px) {
    /* footer {
        bottom: -459px;
    }   */
}

/* media query (small devices)*/
@media screen and (max-width: 600px) {
    /* footer {
        bottom: -526px;
    }   */
  
}
