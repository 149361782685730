
.portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    padding-top: 1.5rem;
    margin: 140px auto;
    position: relative;
}
.portfolio-place-holder {
    position: relative;
    text-align: center;
}
.portfolio-place-holder h1{
    font-family: 'Londrina Outline', cursive;
    font-weight: 400;
    font-size: 9rem;
    position: absolute;
    top: -53px;
    left: 245px;
    letter-spacing: 2rem;
    text-transform: uppercase;
}
.portfolio-item {
    background: var(--clr-white);
    padding: 1.2rem;
    border: 3px solid #e2e8f0;
    border-radius: 1rem;
    height: 100%;
    transition: var(--transition);
    box-shadow: var(--light-shadow);
    text-align: center;
    cursor: pointer;
}
.portfolio-item:hover {
    border: 3px solid  #ea4020;
    box-shadow: var(--dark-shadow);
}
.portfolio-item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}
.portfolio-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition);
}
.portfolio-item h3 {
    margin: 1.2rem 0 2rem;
    color: rgba(41, 41, 41, 0.8);
}
.portfolio-item small {
    
    color: var(--color-white);
}
.portfolio-item-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.show {
    display: block;
    position: absolute;
    bottom: -80px;
}
.show a {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background: var(--color-btn);
    color: var(--color-white);
    border-radius: 0.5rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    transition: var(--transition);
}


/* media query (medium devices)*/
@media screen and (max-width: 1024px) {
    .portfolio-container{
        grid-template-columns: repeat(2, 370px);
        justify-content: center;
        gap: 1rem;
    }
    .portfolio-item-cta > .btn {
        padding: 0.3rem 1.5rem;
     }
    .portfolio-place-holder h1{
        font-size: 4rem;
        left: 17px;
    }
 }
 
 
 /* media query (small devices)*/
 @media screen and (max-width: 600px) {
     .portfolio-container{
         grid-template-columns: 335px;
         justify-content: center;
         gap: 1rem;
     }
     .portfolio-item-cta {
        gap: 0;
     }
     .portfolio-item-cta > .btn {
        padding: 0.3rem 1rem;
     }
     .portfolio-place-holder h1{
        top: -7px;
        left: 24px;
        font-size: 2.2rem;
        letter-spacing: 1.5rem;
    }
    .portfolio-item {
        padding: 0.1rem;
    }
    .portfolio-item-image  img{
        width: 94%;
    }
 }
   
