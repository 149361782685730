:root {
    --color-bg: #15193c;
    --color-bg1: #011627;
    --color-bg-variant: #2c2c6d;
    --color-primary: #516cf5;
    --color-primary-variant: #da3656;
    --color-secondary: #e7a317;
    --color-btn: #ea4020;
    --color-btn1: #f71735;
    --color-white: #fff;
    --color-light: rgba(240, 240, 240, 0.9);
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

}

[data-theme="dark"]  {
    background: var(--color-bg1);
    color: var(--color-text);  
    transition: all 0.5s ease-in-out;
}
[data-theme="light"]  {
    background: var(--color-light);
    color: var(--color-text-light);
    transition: all 0.5s ease-in-out;
}
[data-theme="dark"] nav {
    background: #15193c;
    color: var(--color-white);  
    transition: all 0.5s ease-in-out;
}
[data-theme="light"] nav {
    background: var(--color-light);
    color: var(--color-text-light);
    transition: all 0.5s ease-in-out;
}
[data-theme="dark"] nav a {
    color: var(--color-white);  
}
[data-theme="dark"] .hero-info h1 {
    color: var(--color-white);  
}
[data-theme="dark"] .hero-info h2 {
    color: var(--color-white);  
}
[data-theme="dark"] .hero-info h3 {
    color: var(--color-white);  
}
[data-theme="dark"] .hero-info p {
    color: var(--color-white);  
}
[data-theme="dark"] .hero-image::before {
    border: 20px solid #f71735;
    border-right: 0;
    border-bottom: 0;
}
[data-theme="dark"] .social-icon {
    color: var(--color-white); 
}
[data-theme="light"] .social-icon {
    color: var(--color-bg1); 
}
[data-theme="light"] .social-icon:hover {
    color: #ea4020;
}
[data-theme="dark"] .header-place-holder h1 {
    color: var(--color-light); 
    visibility: visible;
    opacity: 0.5; 
}
[data-theme="dark"] .btn {
    background: var(--color-white);
    color: var(--color-bg1);
    border-color: transparent;
    transform: translateY(-2px);
}
[data-theme="dark"] .btn:hover {
    background: #f71735;
    color: var(--color-white);
    border-color: transparent;
    transform: translateY(-2px);
}

[data-theme="light"] .btn {
    background: var(--color-bg1);
    color: var(--color-white);
    border-color: transparent;
    transform: translateY(-2px);
}
[data-theme="light"] .btn:hover {
    background: #ea4020;
    color: var(--color-white);
    border-color: transparent;
    transform: translateY(-2px);
}

[data-theme="dark"] .about-info p {
    color: rgba(212, 212, 212, .5);
}
[data-theme="dark"] .about-info h2 {
    color: var(--color-white);
}
[data-theme="dark"] .about-info h2::after {
    background: #f71735;
}
[data-theme="dark"] .about-info h5 {
    color: rgba(212, 212, 212, .5);
}
[data-theme="dark"] .hero span {
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: var(--color-light);
}
[data-theme="dark"] .about-place-holder h1{
    color: var(--color-light);
    opacity: 0.5;
}
[data-theme="dark"] .about-info strong {
    color: #f71735;
}
/* footer  dark styles */
[data-theme="dark"] footer {
    background: #15193c;
    color: var(--color-white);
}
[data-theme="dark"] .footer__socials a{
    color: var(--color-white);
}
[data-theme="light"] .footer__socials a{
    color: var(--color-bg1);
}
[data-theme="light"] .footer__socials a:hover{
    color: #ea4020;
}

/*Services page style */
[data-theme="dark"] .services-place-holder h1{
    color: var(--color-light);
    opacity: 0.5;
}
[data-theme="dark"] .service-box {
    background: rgba(255, 255, 255, 0.05);
    border: 0.5px solid #e2e8f0;
    box-shadow: var(--dark-shadow);
}
[data-theme="dark"] .service-box:hover {
    background: var(--color-bg1);
    border: 3px solid #f71735;
    transition: all 0.3s ease-in-out;
}
[data-theme="dark"] .service-box  h3 {
    color: var(--color-white);
}
[data-theme="dark"] .service-box p {
    color: var(--color-light);
}
[data-theme="dark"] .hexagon::before {
    background: #f71735;
}
[data-theme="dark"] .hexagon::after {
    background: #f71735;
}
[data-theme="dark"] .hexagon {
    background: #f71735;
}

/*Portfolio page style */
[data-theme="dark"] .portfolio-place-holder h1{
    color: var(--color-light);
    opacity: 0.5;
}
[data-theme="dark"] .portfolio-item {
    background: rgba(255, 255, 255, 0.05);
    border: 3px solid #e2e8f0;
    box-shadow: var(--dark-shadow);
}
[data-theme="dark"] .portfolio-item:hover {
    background: var(--color-bg1);
    border: 3px solid #f71735;
    transition: all 0.3s ease-in-out;
}
[data-theme="dark"] .portfolio-item  h3 {
    color: var(--color-white);
}
[data-theme="light"] .portfolio-item small {
    color: var(--color-bg1);
}
[data-theme="dark"] .show a {
    background: var(--color-white);
    color: var(--color-bg1);
    border-color: transparent;
    transform: translateY(-2px);
}
[data-theme="dark"] .show a:hover {
    background: #f71735;
    color: var(--color-white);
    border-color: transparent;
    transform: translateY(-2px);
}


/*Contact page style */

[data-theme="dark"] .contact-place-holder h1{
    color: var(--color-light);
    opacity: 0.5;
}

[data-theme="light"] .contact-info {
    background: var(--color-white);
    color: var(--color-bg1);
    box-shadow: var(--light-shadow);
}
[data-theme="light"] .contact-info h3 {
    color: var(--color-bg1);
}
[data-theme="light"] .contact-info p {
    color: #555a64;
}
[data-theme="dark"] .contact-socials a {
    color: var(--color-white);
}
[data-theme="light"] .contact-socials a {
    color: var(--color-bg1);
}
[data-theme="light"] .contact-socials a:hover {
    color: #ea4020;
}

/* Form light theme */
[data-theme="light"] .contact-form {
    background: var(--color-white);
    color: var(--color-bg1);
    box-shadow: var(--light-shadow);
}
[data-theme="light"] .form-control {
    color: var(--color-bg1);
    border: 1px solid #555a64;
}
[data-theme="light"] .contact-form h3 {
    color: var(--color-bg1);
}
