
.about-container {
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-gap: 15%;
    padding-top: 1.5rem;
  }
  .about-me {
    width: 95%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
  }
  .about-me-image {
     border-radius: 2rem;
     overflow: hidden;
     transform: rotate(-10deg);
     transition: var(--transition);
  }
  .about-me-image:hover {
     transform: rotate(0deg);
  }
  .about-me-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.4rem;
  }
  
  /* *****+++++ About info styles ++++++++******* */
  .about-info {
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
  }
 
  .about-info h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    position: relative;
  }
  .about-info h2::after {
    content: '';
    position: absolute;
    left: -15px;
    top: 8px;
    width: 2px;
    height: 25px;
    background-color: var(--color-btn);
  }
  strong {
    color: var(--color-btn);
  }
  .about-info h5 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--color-bg1);
    margin-bottom: 10px;
  }
  
  .about-info p {
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.5;
      color: #292929;
      margin-bottom: 10px;
  }
  .hero {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
  }
  .hero span {
      font-size: 13px;
      border: 1px solid rgba(41, 41, 41, 0.3);
      padding: 5px 15px;
      border-radius: 40px;
      margin: 0 5px 5px 0;
  }
  .about-place-holder h1{
      font-family: 'Londrina Outline', cursive;
      font-weight: 400;
      font-size: 5rem;
      position: absolute;
      top: -41px;
      left: 0;
      letter-spacing: 2rem;
      text-transform: uppercase;
  }
  
  
  /* media query (medium devices)*/
  @media screen and (max-width: 1024px) {
      
      .about-container{
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          gap: 0;
      }
      .about-me {
         width: 50%;
         grid-row: 2 / -1;
         margin: 2rem auto 4rem;
      }
      .about-place-holder h1{
          top: -188px;
          left: 177px;
          font-size: 6rem;
      }
     
  }
  
  /* media query (small devices)*/
  @media screen and (max-width: 600px) {
    /* #about {
      height: 160vh;
    } */
      .about-place-holder h1{
          top: -100px;
          left: 8px;
          font-size: 5rem;
      }
     
  }
