

.container.services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.5rem;
    padding-top: 1.5rem;
    margin: 140px auto;
}
.services-place-holder {
    position: relative;
}
.services-place-holder h1{
    font-family: 'Londrina Outline', cursive;
    font-weight: 400;
    font-size: 9rem;
    position: absolute;
    top: -53px;
    left: 355px;
    letter-spacing: 2rem;
    text-transform: uppercase;
}

.service-box {
    background: var(--clr-white);
    padding: 2.4rem 3rem;
    border: 1px solid #e2e8f0;
    border-radius: 1rem;
    height: 100%;
    transition: var(--transition);
    box-shadow: var(--light-shadow);
    text-align: center;
    cursor: pointer;
}
    .service-box h3 {
        font-size: 1.5rem;
        font-weight: 600;
    }
    .service-box p {
        font-size: 15px;
        color: rgba(41, 41, 41, 0.8);
    }
.service-box:hover {
    border: 3px solid  #ea4020;
    box-shadow: var(--dark-shadow);
}
.service-icon {
    margin: 0 auto;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -60px;
}
.service-icon svg {
    font-size: 25px;
    position: relative;
    z-index: 1;
}
.hexagon {
    color: white;
    width: 80px;
    height: 46px;
    background: #ea4020;
    position: relative;
  }
  
  .hexagon:before, .hexagon:after{
    box-sizing: border-box;
    content: '';
    width: 80px;
    height: 46px;
    position: absolute;
    background: #ea4020;
    z-index:0;
  }
  
  .hexagon:before {
    transform: rotate(60deg);
  }
  .hexagon:after {
    transform: rotate(-60deg);
  }

  /* media query for mediun devicec */
  @media screen and (max-width: 1024px) {
    .container.services-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
    }
    .services-place-holder h1{
        left: 17px;
    }
    
}
/* media query for small devicec */
@media screen and (max-width: 600px) {
   
    .container.services-container {
        grid-template-columns: repeat(1, 1fr);
        gap: 3rem;
        }
        .services-place-holder h1{
            top: -7px;
            left: 24px;
            font-size: 3rem;
            letter-spacing: 1.5rem;
        }
  }