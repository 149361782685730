
nav {
    background: var(--color-white);
    width: 100%;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 111;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 1rem;
    display: flex;
    gap: 0.5rem;
    box-shadow: var(--light-shadow);
    transition: all 1s ease;
}
nav a {
    color: transparent;
    padding: 0.6rem;
    display: flex;
    color: var(--color-bg1);
    font-size: 1.2rem;
    font-weight: 600;
    position: relative;
}
nav a:hover {
    color: var(--color-btn);
}
  
nav a.active{
    opacity: 0.5;
    transition: 3s ease;
}
  
nav a.active::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.2rem;
    width: 100%;
    background: var(--color-btn);
    margin: 0.5rem auto;
    transition: 1s ease;
}
nav a:hover::after,
nav a:focus::after {
    opacity: 1;
    transition: 0.3s ease;
}
.logo {
    margin-right: auto;
}
.theme-icon {
    margin-top: 6px;
}
.icon {
    display: none;
}  


  
/* media query (medium devices)*/
@media screen and (max-width: 1024px) {
    nav {
        gap: 0.5rem;
    }
  }
  
  
  /* media query (small devices)*/
  @media screen and (max-width: 600px) {
   
    .dark nav {
      background: rgba(21, 25, 60, 1);
      box-shadow: var(--dark-shadow);
    }
    .mobile-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 50%;
      transition: all 1.5s ease;
      transform: translateX(-50%);
    }
    
    .mobile-menu-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        top: -150%;
        left: 50%;
        transform: translateX(-50%);
        transition: all 1.5s ease;
      }
      
      
    .icon {
        display: block;
        position: fixed;
        top: 5%;
        left: 80%;
        z-index: 112;
        font-size: 2.5rem;
        color: var(--color-white);
        background: #f71735;
        border-radius: 3px;
        box-shadow: var(--light-shadow);  
    }
    .icon:hover {
       transform: translateY(-2px);
        transition: all 0.3s ease-in-out;
    }
    hr {
      display: block;
      width: 100%;
      border: 0.5px solid var(--color-light);
    }
    .logo {
      margin-right: 0;
    }
   
  }